import * as React from "react";
import {render} from "react-dom";
import {AppContainer} from "react-hot-loader";
import App from "./components/App";

import { Provider } from "react-redux"
import { browserHistory } from "react-router-dom"
import DetectorContainer from "./components/containers/DetectorContainer"
import About from "./components/containers/About"
import Publications from "./components/containers/Publications"
import Media from "./components/containers/Media"
import Contact from "./components/containers/Contact"
import configureStore from "./configureStore"
import * as constants from "./constants"
import { mainStoreState, videosType } from "./types"
const rootEl = document.getElementById("root");


export const emptyVideo = {
  id: 1,
  state: constants.INPUT_STATE,
  results: {
      results: null,
      fakeness: null,
      videoId: null,
      status: null
  },
  preprocess: null
}

// export const emptyVideo : videosType = {
//     id: 1,
//     state: constants.RESULTS_STATE,
//     url: "https://www.youtube.com/watch?v=VWrhRBb-1Ig",
//     title: "Bill Hader channels Tom Cruise [DeepFake]",
//     duration: 194,
//     thumbnail:"https://i.ytimg.com/vi/VWrhRBb-1Ig/sddefault.jpg",
//     channelTitle:"Ctrl Shift Face",
//     description: `Paperspace is the computing cloud platform built for the future to power a wide range of next-generation applications. Learn more on: https://www.paperspace.com Use "ctrlshiftface" referral code for 10$ in account credit. Patreon: https://www.patreon.com/ctrl_shift_face Twitter: https://twitter.com/ctrl_shift_face Source: https://www.youtube.com/watch?v=zS1Aee2X3Yc Music: Alain Goraguer - Le Bracelet DISCLAIMER: No copyright is claimed in this video and to the extent that material may appear to be infringed, I assert that such alleged infringement is permissible under fair use principles in U.S. copyright laws. If you believe material has been used in an unauthorized manner, please contact the poster. Software used: https://github.com/iperov/DeepFaceLab Subtitles by: Matchbox Cinesub https://matchboxcineclub.com/`,
//     publishedAt:"2019-08-06T21:34:26.000Z",
//     results: {
//         results: [
//             // {
//             //     "name":"MesoInception",
//             //     "result": undefined,
//             //     "job_id": null
//             // },
//             {
//                 "name":"FaceNet",
//                 "result":[0.05,0.7,0.82,0.64,0.51,0.57,0.42,0.34,0.4,0.45,0.66,0.71,0.63,0.69,0.71,0.54,0.2,0,0,0,0,0.01,0,0,0,0.01,0.01,0,0.03,0.38,0.21,0.03,0.03,0,0,0,0,0,0,0.02,0.13,0.13,0,0,0.01,0,0,0.33,0.38,0],
//                 "job_id": null
//             }            
//         ],
//             "fakeness": 0.87,
//             "videoId":"VWrhRBb-1Ig"
//     },
//     snippet: {
//         start: 20,
//         end: 70
//     }
// }

export const emptyState : mainStoreState = {
    videos: [emptyVideo],
    server: {
      healthy: true,
      methods: [{
              "active": true,
              "desc": "Complex frame-by-frame detector (2020). Looks for incosistencies within individual frame pixels.",
              "name": "EfficientNet",
              "type": "Intra-frame detection"
          },
          {
              "active": false,
              "desc": "Complex frame-by-frame detector (2019). Looks for incosistencies within individual frame pixels.",
              "name": "ClassNSeg",
              "type": "Intra-frame detection"
          },
          {
              "active": false,
              "desc": "Simple frame-by-frame detector (2018). Looks for incosistencies within individual frame pixels.",
              "name": "MesoInception",
              "type": "Intra-frame detection"
          },
          {
              "active": true,
              "desc": "Complex temporal-based detector (2020). Looks for incosistencies between the frames.",
              "name": "EffTemporal",
              "type": "Inter-frame detection"
          },
          {
              "active": false,
              "desc": "Complex temporal-based detector (2019). Looks for incosistencies between the frames.",
              "name": "XcepTemporal",
              "type": "Inter-frame detection"
          },
          {
              "active": false,
              "desc": "Simple temporal-based detector (2019). Looks for incosistencies between the frames.",
              "name": "FaceNetTemporal",
              "type": "Inter-frame detection"
          },
          {
              "active": false,
              "desc": "Complex temporal-based audio detector (2020).",
              "name": "AudioDetector",
              "type": "Artifically generated audio detection"
      }],
      pages: [
          {
              name: "Home",
              url: "/",
              component: DetectorContainer,
              isUnique: true
          },
          {
              name: "About",
              url: "/about",
              component: About,
              isUnique: false
          },
          {
            name: "Publications",
            url: "/publications",
            component: Publications,
            isUnique: false,
          },
          {
              name: "Media",
              url: "/media",
              icon: "paper-clip",
              component: Media,
              isUnique: false
          },
          {
              name: "Contact",
              url: "/contact",
              component: Contact,
              isUnique: false
          }
      ]
    }
}

const store = configureStore(browserHistory, {
    videos: [ emptyVideo ],
    server: {
        pages: [
            {
                name: "Home",
                url: "/",
                component: DetectorContainer,
                isUnique: true
            },
            {
                name: "About",
                url: "/about",
                component: About,
                isUnique: false
            },
            {
                name: "Publications",
                url: "/publications",
                component: Publications,
                isUnique: false,
            },
            {
                name: "Media",
                url: "/media",
                icon: "paper-clip",
                component: Media,
                isUnique: false
            },
            {
                name: "Contact",
                url: "/contact",
                component: Contact,
                isUnique: false
            }
        ],
        methods: [],
        healthy: false,
    }
});

// console.log(store.getState())

// const testVideo = {
//     type: "VIDEO:TEST",
//     payload: {
//         state: "just test"
//     }
// }

// store.dispatch(testVideo)

// console.log(store.getState())

render(
    <AppContainer>
        <Provider store={store}>
            <App/>
        </Provider>
    </AppContainer>,
    rootEl
);

// Hot Module Replacement API
declare let module: { hot: any };

if (module.hot) {
    module.hot.accept("./components/App", () => {
        const NewApp = require("./components/App").default;

        render(
            <AppContainer>
                <Provider store={store}>
                    <NewApp/>
                </Provider>
            </AppContainer>,
            rootEl
        );
    });
}
