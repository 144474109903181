import * as React from "react"
import { Tooltip, Icon } from "antd"
import "./../../assets/scss/TimelineBlock.scss"
import { MEDIUM_FAKENESS, HIGH_FAKENESS } from "./../../constants"

/**
 * Show a timeline parallel to the YT video player
 * Add padding on the sides to align the processed bit with the video
 * Click on the timeline to jump to the frame
 * Hover on the timeline to view fakeness score
 * Timelines load as the scores become available
 */

// padding around the timeline if selected from the middle
const optionalPadding = (start, end, total) => {
    return {
        'paddingLeft': ((start-0) / total)*100 + '%',
        'paddingRight': ((total-end) / total)*100 + '%'
    }
}

const TimelineBlock = (props) => {

    const timelineMap = (src, methods) => {
        if(src == null) return null
        
        const {result, name} = src
        
        const inner = (result) ? result.map((data, i) => {
            let fakeness = 'real'
            if(data > HIGH_FAKENESS) {
                fakeness = 'fake'
            } else if (data > MEDIUM_FAKENESS) {
                fakeness = 'suspicious'
            }
            return  <Tooltip key={name+i} title={data.toFixed(2) * 100 + "%"} overlayClassName={fakeness}>
                        <span onClick={ () => props.onSeek(props.snippet.start + i) } className={fakeness}></span>
                    </Tooltip>
        }) : null
    
        let description = ""
        let mtype = ""
        for (let m of methods) {
            if(m['name'] == name) {
                description = `[${name}] ${m['desc']}`
                mtype = m['type']
                break
            }
        }
        
        let status = null
        switch (src.result) {
            case null:
                status = <Icon type="loading" />
                break;
            case undefined:
                status = <Icon className="fakeText" type="close-circle" />
                break;
            default:
                status = <Icon className="realText" type="check-circle" />
                break;
        }
        
        return (
            <div key={name+"-reel"} className="timeline-container">
                <div style={(result) ? optionalPadding(props.snippet.start, props.snippet.end, props.duration) : null}>
                    <span>{mtype}</span><span className="timeline-status">{status}</span>
                    <Tooltip key={name+"-tooltip"} title={description}>
                        <Icon type="info-circle" />
                    </Tooltip>
                    <div className="timeline">
                        {inner}
                    </div>
                </div>
            </div>
        )
    }

    const mainTimeline = props.reel.map( v => timelineMap(v, props.methods) )

    return (
        <div className="timelines-container">
            {mainTimeline}
        </div>
    )
}

export default TimelineBlock