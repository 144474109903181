import * as React from "react"
import { Progress, Tooltip, Icon } from "antd"
import { HIGH_FAKENESS, MEDIUM_FAKENESS } from "./../../constants"
import { fakenessMessage } from "../../constants/messages"

interface propsType {
    value: number,
    loading: boolean
}

const OverallResultComponent = (props: propsType) => {
    let strokeColor = '#84BD00'
    if( props.value > HIGH_FAKENESS*100 ) {
        strokeColor = '#DA291C'
    } else if ( props.value > MEDIUM_FAKENESS*100) {
        strokeColor = '#F76902'
    }

    const progressText = (percent) => {
        if(props.loading) {
            return <Icon
                type="loading"
                style={{ fontSize: '16px', background: 'transparent', marginRight: '15px' }}
                spin
            />
        } else if(percent == 100) {
            return (<span className="fakeText">Fake</span>)
        }
        else {
            return `${percent}%`
        }
    }
    return (
        <div className="sub-block overall-result-block">
            <div>
                <h2>Fakeness</h2>
                <Tooltip key={name+"-tooltip"} title={ fakenessMessage }>
                    <Icon type="info-circle" />
                </Tooltip>
            </div>
            <Progress type="circle" percent={props.value}  width={80} strokeWidth={8} strokeColor={strokeColor} format={progressText}/>
        </div>
    )
}

export default OverallResultComponent