import * as React from "react"
import { useCookies } from "react-cookie"
import "./../../assets/scss/DetectorContainer.scss"
import VideoBlock from "../blocks/VideoBlock";
import LoginBlock from "../blocks/LoginComponent"
import OfflineBlock from "../blocks/OfflineBlock"
import { connect } from "react-redux"
import { storeState } from "../../types"

interface DetectorContainerProps {
    blocks: Array<any>,
    healthy: boolean
}

const DetectorContainer: React.FunctionComponent<DetectorContainerProps> = (props) => {
    const [ cookies ] = useCookies(['dfin'])
    const sessionActive = cookies['dfin']
    const domBlocks = props.blocks.map((block) =>
        <VideoBlock key={block['id']} id={block['id']} />
    )
   
    return (
        <div className="container block-container">
                { (!props.healthy) ? <OfflineBlock /> : (sessionActive) ? domBlocks : <LoginBlock /> }
        </div>
    )
}

const mapStateToProps = (state: storeState) => {
    
    return {
        blocks: state.videos,
        healthy: state.server.healthy
    }
}

export default connect(mapStateToProps)(DetectorContainer)