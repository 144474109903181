import * as React from "react"
import { useState } from "react"
import { connect } from "react-redux"
import ReactPlayer from "react-player"
import { useCookies } from "react-cookie"
import "../../assets/scss/VideoBlock.scss"
import { Button, notification, Spin, Icon } from "antd"
import { BACKEND_ADDRESS } from "../../constants"

import { LOADING_STATE, INPUT_STATE, SELECTED_STATE, UPLOADING_STATE, RESULTS_STATE, MEDIUM_FAKENESS, HIGH_FAKENESS, MAX_PROCESS_DURATION_S } from "../../constants"
import { storeState } from "../../types"
import { matchYoutubeUrl, getYoutubeId, convertISO8601ToSeconds } from "../../helpers"
import { removeVideo, addVideo, updateVideo, updateResults } from "../../actions/videoActions";

import VideoInputBlock from "./VideoInputBlock"
import VideoSelectedBlock from "./VideoSelectedBlock"
import VideoResultsBlock from "./VideoResultsBlock"
import IssueSubmissionBlock from "./IssueSubmission"
import LoadingBlock from "./LoadingBlock"
import { selectMethods } from "../../actions/serverActions"
import axios from "axios"

const Config = require('config')

const VideoBlock = (props) => {
    const [player, setPlayer] = useState(null)
    const [videoPlaying, setVideoPlaying] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['dfin'])
    const [bugReport, setBugReport] = useState(null)
    const setBlockState = (stateName, id = props.video.id) => {
        props.updateVideo({
            id: id,
            state: stateName
        })
    }

    const showMessage = (type, message) => {
        notification[type]({
            message: "Sorry, an error has occured.",
            description: message
        })
    }

    const handleBug = () => {
        setBugReport(null)
        setBlockState(INPUT_STATE)
    }

    const manageSession = (direction) => {
        if (direction === "lin") {
            setCookie('dfin', true, { sameSite: true })
        } else if (direction === "lout") {
            removeCookie('dfin')
        }
    }

    const getFakeness = (value) => {
        if (value > HIGH_FAKENESS) {
            return 'fake'
        } else if (value > MEDIUM_FAKENESS) {
            return 'suspicious'
        } else if (value > 0) {
            return 'real'
        }
        return 'loading-state'
    }

    /**
     * Player Code
     */
    const onSetPlayer = (ref) => {
        setPlayer(ref)
    }

    const playerSettings = {
        playing: videoPlaying === true,
        controls: true,
        muted: true,
        width: '100%',
        height: '100%',
        config: {
            youtube: {
                playerVars: {
                    preload: 1,
                    modestbranding: 1,
                    rel: 1,
                }
            }
        },
    }

    const onSeek = (value) => {
        player['player'].player.player.seekTo(value)
    }

    const playerDOM = (
        <div className="player-wrapper">
            <ReactPlayer ref={onSetPlayer} className="react-player-container" url={props.video.url} {...playerSettings}></ReactPlayer>
        </div>
    )

    /**
     * API Calls
     */

    const getVideoInfo = async (video_id) => {
        try {
            const response = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
                params: {
                    key: Config.ytapi,
                    part: 'snippet,contentDetails',
                    id: video_id,
                },
            }).then(response => {
                const { data } = response
                const { items } = data
                if (items && items.length > 0) {
                    const { title, thumbnails, channelTitle, description, publishedAt, tags } = items[0].snippet
                    const { duration } = items[0].contentDetails
                    const { standard } = thumbnails

                    props.updateVideo({
                        id: props.id,
                        title: title,
                        duration: convertISO8601ToSeconds(duration),
                        thumbnail: standard.url,
                        channelTitle: channelTitle,
                        description: description,
                        publishedAt: publishedAt,
                        tags: tags,
                        results: {
                            results: props.methods.map(
                                m => (m['active']) ? ({
                                    name: m['name'],
                                    result: null,
                                    job_id: null
                                }) : null
                            )
                        }
                    })

                    uploadRequest({
                        url: props.video.url
                    })
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    const uploadRequest = (data) => {
        setBlockState(UPLOADING_STATE)

        fetch(
            BACKEND_ADDRESS + '/upload',
            {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(data)
            }
        )
            .then(response => response.json())
            .then(({ success, message }) => {
                //todo show message
                if (success) {
                    setBlockState(SELECTED_STATE)
                } else if (message === "No user session") {
                    manageSession("lout")
                }
            })
            .catch(error => {
                const errorMessage = (
                    <div>
                        Failed to upload this video.
                    </div>
                )
                setBugReport(error)
                showMessage('error', errorMessage)
                console.error("Error:", error)
            })
    }

    const evaluationRequest = (data, order, job_id) => {
        // call recursive evaluation requests
        while (props.video.results.results[order] === null) {
            // if the item is null and is in the middle of the array, try to go next
            if (props.video.results.results.length - 1 > order) {
                order++
            } else {
                // else we've reached the end of the methods array and we just get out
                return
            }
        }

        data['methods'] = [props.video.results.results[order].name]
        data['job_id'] = job_id

        if (Config.mode == 'development') {
            data['test'] = 5
        }

        /**
         *   Intermediate Response
         *   {
         *      "message": {
         *           "job_id": "7e176a13-3c2e-4290-ab0e-64615d43448a",
         *           "status": "started", or "status": "queued"
         *           "queue": 1,
         *       },
         *       "success": true
         *   }
         * 
         *   Final Response
         *   {
         *       "message": {
         *           "fakeness": 0.8,
         *           "results": [
         *               {
         *                   "name": "MesoInception",
         *                   "result": [
         *                       0.8,
         *                       ...
         *                       0.8
         *                   ]
         *               }
         *           ],
         *           "status": "finished",
         *           "videoId": "VWrhRBb-1Ig"
         *       },
         *       "success": true
         *   }
         */

        const resultsPartition = (x, start, end) => x.slice(Math.ceil(x.length * start / 100), Math.floor(x.length * end / 100))

        if (data["url"].indexOf('VWrhRBb-1Ig') > 0) { //placeholder for demos
            if (data["methods"] == "EfficientNet") {
                setTimeout(() => {
                    props.updateResults(
                        props.id,
                        {
                            "fakeness": 0.80,
                            "results": [
                                {
                                    "name": "EfficientNet",
                                    "result": resultsPartition(
                                        [0.21, 0.11, 0.03, 0.2, 0.24, 0.12, 0.11, 0.29, 0.13, 0.17, 0.14, 0.02, 0.06, 0.25, 0.05, 0.13, 0.15, 0.15, 0.27, 0.1, 0.06, 0.19, 0.07, 0.03, 0.1, 0.17, 0.26, 0.23, 0.25, 0.1, 0.18, 0.27, 0.01, 0.28, 0.1, 0, 0.03, 0.23, 0.13, 0.01, 0.12, 0.06, 0.06, 0.26, 0.02, 0.15, 0.05, 0.26, 0.03, 0.28, 0.13, 0.13, 0.11, 0.08, 0.13, 0.66, 0.16, 0.56, 0.05, 0.06, 0.22, 0.04, 0.02, 0.24, 0.26, 0.03, 0.13, 0.26, 0.1, 0.12, 0, 0.07, 0.09, 0.18, 0.25, 0.2, 0.02, 0.29, 0.27, 0.21, 0.24, 0.11, 0.64, 0.48, 0.5, 0.38, 0.21, 0.2, 0.48, 0.32, 0.14, 0.51, 0.48, 0.05, 0.24, 0.13, 0.01, 0.2, 0.04, 0.22, 0.1, 0.07, 0.14, 0.21, 0.43, 0.14, 0.18, 0.33, 0.03, 0.09, 0.29, 0.33, 0.56, 0.65, 0.29, 0.71, 0.28, 0.35, 0.1, 0.6, 0.01, 0.55, 0.17, 0.17, 0.22, 0.22, 0.55, 0.28, 0.49, 0.61, 0.75, 0.16, 0.18, 0.03, 0.61, 0.16, 0.57, 0.24, 0.39, 0.32, 0.45, 0.08, 0.02, 0.22, 0.16, 0.27, 0.05, 0.27, 0.02, 0.11, 0.04, 0.12, 0.28, 0.09, 0.13, 0.03, 0.09, 0.21, 0.73, 0.79, 0.07, 0.78, 0.0, 0.0, 0.11, 0.01, 0.13, 0.07, 0.19, 0.16, 0.11, 0.02, 0.14, 0.16, 0.18, 0.14, 0, 0.02, 0.12, 0.04, 0.14, 0.1, 0.13, 0.06, 0.09, 0.18, 0.14, 0.03, 0.12, 0.03, 0.11, 0.1, 0.0],
                                        data['start'],
                                        data['end']
                                    )
                                }
                            ],
                            "status": "finished",
                            "videoId": "VWrhRBb-1Ig"
                        }
                    )
                    if (order < props.video.results.results.length - 1) {
                        evaluationRequest(data, ++order, 0)
                    }
                }, 5000);
            } else if (data["methods"] == "EffTemporal") {
                setTimeout(() => {
                    props.updateResults(
                        props.id,
                        {
                            "fakeness": 0.82,
                            "results": [
                                {
                                    "name": "EffTemporal",
                                    "result": resultsPartition(
                                        [0.1, 0.25, 0.16, 0.12, 0.23, 0.01, 0.1, 0.07, 0, 0.29, 0.02, 0.07, 0.08, 0.07, 0.23, 0.02, 0.18, 0.17, 0.24, 0.29, 0.29, 0.27, 0.26, 0.1, 0.24, 0.07, 0.02, 0.16, 0.05, 0.14, 0.18, 0.21, 0.29, 0.04, 0.03, 0.21, 0.11, 0, 0.25, 0.17, 0.25, 0.19, 0.12, 0.01, 0.13, 0.04, 0, 0.08, 0.04, 0.28, 0.09, 0.15, 0.05, 0, 0.22, 0.52, 0.38, 0.51, 0.03, 0.06, 0.16, 0.29, 0.23, 0.14, 0.11, 0.14, 0.18, 0.2, 0.08, 0.2, 0.19, 0.22, 0.15, 0.02, 0.02, 0.14, 0.24, 0.19, 0.19, 0.18, 0.2, 0.24, 0.32, 0.45, 0.51, 0.1, 0.27, 0.32, 0.1, 0.25, 0.7, 0.7, 0.52, 0.19, 0.28, 0.18, 0.01, 0.28, 0.18, 0.09, 0.05, 0.19, 0.21, 0.04, 0.58, 0.6, 0.55, 0.13, 0.19, 0.19, 0.39, 0.74, 0.38, 0.25, 0.59, 0.66, 0.75, 0.27, 0.02, 0.68, 0.18, 0.1, 0.62, 0.55, 0.07, 0.57, 0.08, 0.4, 0.06, 0.16, 0.45, 0.21, 0.32, 0.63, 0.45, 0.53, 0.44, 0.13, 0.49, 0.61, 0.47, 0.07, 0.22, 0.03, 0.02, 0.28, 0.27, 0.08, 0.09, 0.26, 0.1, 0.08, 0.19, 0.22, 0.01, 0.18, 0.23, 0.23, 0.13, 0.68, 0.73, 0.79, 0.07, 0.78, 0, 0.09, 0, 0.14, 0.04, 0.07, 0.15, 0.07, 0.07, 0.09, 0, 0.06, 0, 0.11, 0.01, 0.07, 0, 0.03, 0.04, 0.17, 0.02, 0.08, 0.02, 0.14, 0.01, 0.19, 0.09, 0.18, 0.0],
                                        data['start'],
                                        data['end']
                                    )
                                }
                            ],
                            "status": "finished",
                            "videoId": "VWrhRBb-1Ig"
                        }
                    )
                    if (order < props.video.results.results.length - 1) {
                        evaluationRequest(data, ++order, 0)
                    }
                }, 5000);
            } else if (data["methods"] == "AudioDetector") {
                setTimeout(() => {
                    props.updateResults(
                        props.id,
                        {
                            "fakeness": 0.0,
                            "results": [
                                {
                                    "name": "AudioDetector",
                                    "result": resultsPartition(
                                        [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.01, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
                                        data['start'],
                                        data['end']
                                    )
                                }
                            ],
                            "status": "finished",
                            "videoId": "VWrhRBb-1Ig"
                        }
                    )
                    if (order < props.video.results.results.length - 1) {
                        evaluationRequest(data, ++order, 0)
                    }
                }, 5000);
            }
        } else {
            fetch(
                BACKEND_ADDRESS + "/evaluate",
                {
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify(data)
                }
            ).then(response => {
                try {
                    return response.json()
                } catch (e) {
                    showMessage('error', e.message)
                    setBlockState(SELECTED_STATE)
                }
            }).then(({ message, success }) => {
                if (success) {
                    // evaluation call didn't fail, might be finished or qued
                    if (message['status'] == 'started' || message['status'] == 'queued') {
                        // process started, re-request with same params, poll every 3 seconds
                        setTimeout(() => {
                            evaluationRequest(data, order, message['job_id'])
                        }, 3000)
                    } else {
                        // job finished, pass data, start another method if available

                        props.updateResults(
                            props.id,
                            message
                        )
                        if (order < props.video.results.results.length - 1) {
                            evaluationRequest(data, ++order, 0)
                        }
                    }
                } else {
                    // evaluation call failed, try other evaluation methods if available
                    showMessage('error', message)

                    props.updateResults(
                        props.id,
                        {
                            fakeness: 0,
                            results: undefined,
                        }
                    )

                    if (order < props.video.results.results.length - 1) {
                        evaluationRequest(data, ++order, 0)
                    }
                }
            })
        }

    }

    // https://www.youtube.com/watch?v=VWrhRBb-1Ig
    const preprocessRequest = (data, job_id) => {
        if (job_id == 0) {
            setBlockState(LOADING_STATE)
        }

        if (Config.mode == 'development') {
            data['test'] = 5
        }

        data["job_id"] = job_id

        /**
         *   Initial response:
         *   {
         *       "message": {
         *           "job_id": "9880c486-1c3c-45d4-8f3c-b84b39523fb6",
         *           "queue": 1,
         *           "status": "started" or "status": "queued"
         *       },
         *       "success": true
         *   }
         *   
         *   Final Response:
         *   {
         *       "message": "Preprocessing data exists",
         *       "success": true
         *   }
         */
        if (data["url"].indexOf('VWrhRBb-1Ig') > 0) { // placeholder for demos
            // preprocessing finished change status and move on to evaluation
            setTimeout(() => {
                props.updateVideo(
                    props.id,
                    {
                        status: 'done',
                        job_id: null
                    }
                )
                setBlockState(RESULTS_STATE)
                evaluationRequest(data, 0, 0)
            }, 4000);
        } else {
            fetch(
                BACKEND_ADDRESS + "/preprocess",
                {
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify(data)
                }
            ).then(response => {
                try {
                    return response.json()
                } catch (e) {
                    showMessage('error', e.message)
                    setBlockState(SELECTED_STATE)
                }
            }).then(({ message, success }) => {
                if (success) {
                    // preprocessing didnt fail, might be qued or finished
                    // showMessage('success', message)
                    if (message['status'] == 'started' || message['status'] == 'queued') {
                        // update video state and resend request to get updates, poll every 1 seconds
                        props.updateVideo({
                            id: props.id,
                            preprocess: {
                                status: message['status'],
                                job_id: message['job_id']
                            }
                        })
                        setTimeout(() => {
                            preprocessRequest(data, message['job_id'])
                        }, 1000)
                    } else {
                        // preprocessing finished change status and move on to evaluation
                        props.updateVideo(
                            props.id,
                            {
                                status: 'done',
                                job_id: null
                            }
                        )
                        setBlockState(RESULTS_STATE)
                        evaluationRequest(data, 0, 0)
                    }
                } else {
                    // something failed
                    showMessage('error', message)
                    setBlockState(SELECTED_STATE)
                }
            })
        }
    }

    /**
     * Event Handlers
     */
    const onUpload = (e) => {
        e.preventDefault()
        if (!matchYoutubeUrl(props.video.url)) {
            alert("Invalid YouTube URL")
            return
        }

        getVideoInfo(getYoutubeId(props.video.url))
    }

    const onChangeURL = (e) => {
        const { value } = e.target
        props.updateVideo({
            id: props.id,
            url: value
        })
    }

    const onProcess = (e) => {
        e.preventDefault()

        const formData = new FormData(e.target)
        let start = parseInt(formData.get('start').toString())
        let end = parseInt(formData.get('end').toString())
        let startP = Math.floor(start / props.video.duration * 100)
        let endP = Math.floor(end / props.video.duration * 100)

        // console.log(start, end, props.video.duration, startP, endP)

        if (!start) {
            start = 0
        }
        if (!end) {
            end = Math.min(Math.floor(100 / props.video.duration * MAX_PROCESS_DURATION_S), 100)
        }

        props.updateVideo({
            id: props.id,
            snippet: {
                start: start,
                end: end
            },
            preprocess: {
                status: null,
                job_id: null
            }
        })

        preprocessRequest({
            url: props.video.url,
            start: startP,
            end: endP,
        }, 0)
    }

    let output = null
    let addMore = null
    let classNames = ['block', 'video-block']

    switch (props.video.state) {
        case SELECTED_STATE:
            classNames.push('selected-state')
            output = (
                <div>
                    {playerDOM}
                    <VideoSelectedBlock
                        onSubmit={onProcess}
                        onBack={() => { setBlockState(INPUT_STATE) }}
                        title={props.video.title}
                        duration={props.video.duration}
                        setVideoPlaying={setVideoPlaying}
                        seekTo={onSeek}
                        methods={props.methods}
                        selectMethods={props.selectMethods}
                        updateVideo={props.updateVideo}
                        id={props.id}
                    />
                </div>
            )
            break
        case RESULTS_STATE:
            classNames.push(getFakeness(props.video.results['fakeness']))
            classNames.push('results-state')
            output = (
                <div>
                    {playerDOM}
                    {(props.methods) ? <VideoResultsBlock data={props.video} methods={props.methods} onSeek={onSeek} /> : <p>Nada</p>}

                </div>
            )
            break
        case LOADING_STATE:
            classNames.push('loading-state')

            output = (
                <div>
                    {playerDOM}
                    <LoadingBlock status={props.video.preprocess.status} />
                </div>
            )
            break
        case UPLOADING_STATE:
            classNames.push('uploading-state')
            output = (
                <div>
                    <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} /> Uploading
                    <div className="notification">
                        Please Refresh the page if it's stuck for more than 30 seconds<br />
                        Apologies for the bug, we're working on upgrading our system and haven't gotten to resolving the YouTube download bug for the current system.
                    </div>
                </div>
            )
            break
        default:
            classNames.push('input-state')
            output = <VideoInputBlock url={props.video.url} onUrlChange={onChangeURL} onFormSubmit={onUpload} />
            break
    }

    const closeButton = (
        <Button className="close" shape="circle" icon="close" onClick={() => props.onClose(props.video.id)} />
    )

    // console.log(props.video, props.total, props.methods)
    return (
        <div className={classNames.join(' ')}>
            {output}
            {(props.video.state != INPUT_STATE) ? <IssueSubmissionBlock url={props.video.url} onAction={() => handleBug()} bugReport={bugReport} state={props.video.state} /> : null}
            {(props.video.state !== INPUT_STATE) ? closeButton : null}
        </div>
    )
}

const mapStateToProps = (state: storeState, initProps) => ({
    video: state.videos.find(item => item['id'] === initProps.id),
    total: state.videos.length,
    methods: state.server.methods
})

const mapActionsToProps = {
    onClose: removeVideo,
    onAddVideo: addVideo,
    updateVideo: updateVideo,
    selectMethods: selectMethods,
    updateResults: updateResults
}

export default connect(mapStateToProps, mapActionsToProps)(VideoBlock)