import * as React from "react";
import { useEffect } from "react"
import "../assets/less/theme.less";
import "../assets/scss/App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { useCookies } from "react-cookie"
import ReactGA from 'react-ga4'

import HeaderContainer from "./containers/HeaderContainer"
import FooterContainer from "./containers/FooterContainer"
import p404 from "./containers/404"
import { storeState } from "../types"
import { updateServer, updateMethods, updateHealth } from "../actions/serverActions"
import { notification } from "antd"
import { BACKEND_ADDRESS } from "../constants"
const Config = require('config')


import { init as emailjsinit } from "emailjs-com"

const App = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['dfin'])


    const showMessage = (type, message) => {
        notification[type]({
            message: "Sorry, an error has occured.",
            description: message,
            duration: 0
        })
    }

    const manageSession = (direction) => {
        if (direction === "lin") {
            setCookie('dfin', true, { sameSite: true })
        } else if (direction === "lout") {
            removeCookie('dfin')
        }
    }

    const requestMethods = () => {
        fetch(
            BACKEND_ADDRESS + '/available',
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then(response => response.json())
            .then(({ message, success }) => {
                //todo show message
                console.log("logging in")
                if (success) {
                    manageSession("lin")
                    if (!props.healthy) {
                        props.updateServer({
                            methods: message,
                            healthy: true
                        })
                    } else {
                        props.updateMethods(message)
                    }
                } else if (message === "No user session") {
                    manageSession("lout")
                    if (!props.healthy) {
                        props.updateHealth(true)
                    }
                }
            })
            .catch(error => {
                showMessage('error', error.message)
                props.updatServer({
                    methods: [],
                    healthy: false
                })
                console.error("Error:", error)
            })
    }

    useEffect(() => {
        if (!window["EJ"]) { // initialize emailjs
            emailjsinit(Config.emailjs)
            window["EJ"] = true
        }
        if (!window["GA"]) {
            ReactGA.initialize(Config.ga) //react-ga4
            window["GA"] = true
        }

        // server updates
        if (props.methods.length == 0) {
            console.log("methods:", props.methods)
            requestMethods()
        }
    })

    return (
        <div className="app">
            <Router>
                <HeaderContainer />
                <main>
                    <Switch>
                        {/* 
                            the below code pretty much does this, but dynanmically
                            <Route exact path="/" component={DetectorContainer}/>
                            <Route path="/about" component={About}/>
                            <Route path="/media" component={Media}/>
                            <Route path="/contact" component={Contact}/> */
                        }
                        {
                            props.pages.map(({ component, icon, isUnique, name, url }) => <Route
                                exact={isUnique ? true : null}
                                path={url}
                                component={component}
                                key={name + "-r"} />)
                        }
                        <Route component={p404} />
                    </Switch>
                </main>
                <FooterContainer />
            </Router>
        </div>
    );
}

const mapStateToProps = (state: storeState) => ({
    pages: state.server.pages,
    methods: state.server.methods,
    healthy: state.server.healthy
})

const mapActionsToProps = {
    updateMethods: updateMethods,
    updateServer: updateServer,
    updateHealth: updateHealth,
}

export default connect(mapStateToProps, mapActionsToProps)(App)