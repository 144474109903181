import * as React from "react"
import { Row, Col, Button, Modal } from "antd"
import reactElementToJSXString from 'react-element-to-jsx-string'
import { useCookies } from "react-cookie"
import { send } from "emailjs-com"
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3"
const Config = require("config")

const IssueSubmission = (props) => {
    const [captcha, setCaptcha] = React.useState(null)
    const [sent, setSent] = React.useState(false)
    const [contents, setContents] = React.useState("")

    const handleCancel = () => {
        props.onAction()
    }

    const message = (
        <ul>
            <li>YouTube URL: {props.url}</li>
            <li>Browser Info: {navigator.userAgent}</li>
            <li>Screen: {props.state.split(":")[1]}</li>
            <li>Report: {props.bugReport}</li>
        </ul>
    )

    const onFormSubmit = (e) => {
        if(captcha) {
            const templateParams = {
                from_name: "bugs@defake.app",
                to_name: 'bugs@defake.app',
                subject: 'Bug report',
                message_html: reactElementToJSXString(message)
            }
    
            send('zoho', 'template_HSFtImfP', templateParams)
            setSent(true)
            setTimeout(() => {
                props.onAction()
            }, 1000);
        } else {
            setContents('Captcha Failed')
        }
    }

    const onCaptcha = (value) => {
        setCaptcha(value)
    }

    return (
        <Modal
            visible={props.bugReport != null}
            title="Bug Reporting"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>Return</Button>,
                <Button key="submit" type="primary" onClick={onFormSubmit}>
                Submit
                </Button>,
            ]}
        >
            <p>Info to be sent:</p>
            {message}
            {contents}
            <GoogleReCaptchaProvider reCaptchaKey={ Config.recaptcha }>
                <GoogleReCaptcha onVerify={ onCaptcha } />
            </GoogleReCaptchaProvider>
        </Modal>
    )
}

export default IssueSubmission