import * as React from "react"
import { Button } from "antd"
import * as domtoimage from "dom-to-image"

const ExportComponent = (props) => {
    
    const exportAsCSV = (e) => {
        let csvContent = "data:text/csv;charset=utf-8,"
        props.results.forEach(row => {
            if(row !== undefined && row !== null && row['result'] && Array.isArray(row['result'])) {
                csvContent += row['name'] + "," + row['result'].join(",") + "\n"
            }
            // else if( typeof row === "string" ) {
            //     csvContent += row + "," + row + "\n"
            // } else if ( typeof row === "number" ) {
            //     csvContent += row + "," + row.toString() + "\n"
            // }
        });

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    const exportAsPNG = (e) => {
        // const el = document.getElementById("results-block-" + props.id.toString())
        const el = document.getElementById("results-block")
        domtoimage.toJpeg(el, {quality: 0.95})
            .then(function (dataUrl) {
                var link = document.createElement('a')
                link.download = 'defake-results-video-'+props.id.toString()+'.jpeg'
                link.href = dataUrl
                link.click()
            })
    }

    return (
        <div className="sub-block export-component">
            <h2>Export Results</h2>
            <Button type="primary" block={true} disabled={props.disabled} onClick={exportAsCSV}>CSV</Button>
            <Button type="primary" block={true} disabled={props.disabled} onClick={exportAsPNG}>Image</Button>
        </div>
    )
}

export default ExportComponent