import { ActionCreator } from 'redux';
import * as constants from "../constants"
import { serverActionsTypes, serverType } from '../types';

export const updateMethods: ActionCreator<serverActionsTypes> = (data: Array<Object>) => ({
    type: constants.UPDATE_METHODS,
    payload: data
})

export const selectMethods: ActionCreator<serverActionsTypes> = (data: string) => ({
    type: constants.SELECT_METHODS,
    payload: data
})

export const updateHealth: ActionCreator<serverActionsTypes> = (data: boolean) => ({
    type: constants.UPDATE_HEALTH,
    payload: data
})

export const updateServer: ActionCreator<serverActionsTypes> = (data: serverType) => ({
    type: constants.UPDATE_SERVER,
    payload: data
})

const initPages: ActionCreator<serverActionsTypes> = () => ({
    type: constants.INIT_PAGES,
    payload: null
})