import * as constants from "../constants"
import { Reducer } from 'redux';
import { videosType } from "../types"
import { emptyVideo } from "../index"

const videoReducer: Reducer<Array<videosType>> = (state: Array<videosType> = [ emptyVideo ] , {type, payload}) => {
    switch (type) {
        case constants.ADD_VIDEO:
            return {
                ...state,
                payload
            }
        case constants.REMOVE_VIDEO:
            if (state.length > 1) {
                return state.filter(item => { return (item.id === payload) ? false : true } )
            }
            return [ emptyVideo ]
        case constants.UPDATE_VIDEO:
            const updatedItems = state.map( item => {
                if( item.id === payload['id']) {
                    // just aggregate results if one exists and its a results update
                    item = {
                        ...item,
                        ...payload
                    }
                }
                return item
            })
            return updatedItems
        case constants.UPDATE_RESULTS:
            const updatedItemsb = state.map( item => {
                if( item.id === payload['id']) {
                    if( item.results && item.results.results) {
                        // if the name on payload matches the name on one of the existing results template, then place it in
                        // according to our plans, the template should reflect the expected payloads
                        const newResults = item.results.results.map( i => (i && (i['name'] == payload.results.results[0]['name'])) ? payload.results.results[0] : i)
                        item = {
                            ...item,
                            results: {
                                ...item.results,
                                fakeness: Math.max(payload.results.fakeness, (item.results.fakeness)?item.results.fakeness:0),
                                results: newResults
                            }
                        }
                    }                    
                }
                return item
            })
            return updatedItemsb
        default:
            return state
    }
}

export default videoReducer