import * as React from "react"
import { connect } from "react-redux"
import "../../assets/scss/Content.scss"
import { Link } from "react-router-dom"

const p404 = (props) => {

    return (
        <div className="page-container p404">
            <h1>404</h1>
            <p>Whoops! Are you lost?</p>
            <p className="learn-more">
                Click <Link to="/about">here</Link> to learn about the DeFake Project and our publications
            </p>
        </div>
    )
}

const mapStateToProps = state  => state

export default connect(mapStateToProps)(p404)