import * as React from "react"
import { Button, Input } from "antd"
import "../../assets/scss/Theme.scss"

export default function VideoInputBlock(props) {
    return (
        <form onSubmit={props.onFormSubmit}>
            <Input name="url" value={props.url} type="url" placeholder="Enter YouTube URL" allowClear onChange={props.onUrlChange} />
            <Button htmlType="submit" disabled={props.url === ""}>Upload</Button>
        </form>
    )
}