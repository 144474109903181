import * as React from "react"
import { connect } from "react-redux"
import "../../assets/scss/Content.scss"
import { ExportOutlined, CopyOutlined } from '@ant-design/icons'
import { notification } from "antd"

const subPubs = (subpub) => (
    <ul className="publication-sublist">
        {
            subpub.map(({ date, title, publisher, url, award }) => <li key={"pub-subli-" + title.replace(/\s+/g, '-').toLowerCase()}>
                <a href={url} target="_blank">
                    <strong>[{date}]</strong>
                    {" " + title}
                    <p className="publisher">{publisher}{(award) ? (<span title={award}>🏆</span>) : null}</p>
                    <ExportOutlined translate={undefined} className="export-icon" />
                </a>
            </li>)
        }
    </ul>
)

const Publications = (props) => {
    let publicationJSON = require("../../resources/publications.json")
    const publicationList = publicationJSON.sort((a, b) => (new Date(a.date) < new Date(b.date)) ? 1 : -1)

    const showMessage = (type, message) => {
        console.log(message)
        navigator.clipboard.writeText(message)
        notification[type]({
            message: "Copied Bibtex",
            description: message
        })
    }


    const publicationView = (
        <ul className="publication-list">
            {
                publicationList.map(({ date, title, publisher, url, award, versions, bibtex }) => {
                    console.log(title, url)
                    return <li key={"pub-sub-" + title.replace(/\s+/g, '-').toLowerCase()}>
                        <strong>{date}</strong>
                        <a href={url} target="_blank">
                            <h3>{title}</h3>
                            <p className="publisher">{publisher}{(award) ? (<span title={award}>🏆</span>) : null}</p>
                            <ExportOutlined translate={undefined} className="export-icon" />
                        </a>
                        {
                            (bibtex) ? <button className="bibtex-link" onClick={() => showMessage('success', bibtex)}>
                                <CopyOutlined translate={undefined} className="copy-icon" />
                                BibTeX</button> : null
                        }

                        {(versions) ? subPubs(versions) : null}
                    </li>
                }
                )
            }
        </ul>
    )

    return (
        <div className="page-container container">
            <h1>Publications</h1>
            {publicationView}
            <div className="footnote">
                <img style={{ maxHeight: '70px' }} src="https://people.rit.edu/js8365/images/nsf.png" alt="National Science Foundation" />
                <p>
                    The opinions, findings, and conclusions, or recommendations expressed are those of the authors and do not necessarily reflect the views of the National Science Foundation.
                </p>
            </div>
        </div>
    )
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(Publications)