import * as React from "react"

const LabelComponent = () => {
    return (
        <div className="sub-block label-component">
            <h2>Legend</h2>
            <div className="meter">
                <ul>
                    <li className="fake">Fake</li>
                    <li className="suspicious">Suspicious</li>
                    <li className="real">Real</li>
                </ul>
            </div>
        </div>
    )
}

export default LabelComponent