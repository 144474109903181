export const matchYoutubeUrl = (url) => {
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if(url.match(p)){
        return url.match(p)[1];
    }
    return false;
}

export const getYoutubeId = (url) => {
    const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match&&match[1].length==11)? match[1] : false;
}

export const convertISO8601ToSeconds = (input) => {
    const iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;

    const matches = input.match(iso8601DurationRegex);
    // return {
    //     sign: matches[1] === undefined ? '+' : '-',
    //     years: matches[2] === undefined ? 0 : matches[2],
    //     months: matches[3] === undefined ? 0 : matches[3],
    //     weeks: matches[4] === undefined ? 0 : matches[4],
    //     days: matches[5] === undefined ? 0 : matches[5],
    //     hours: matches[6] === undefined ? 0 : matches[6],
    //     minutes: matches[7] === undefined ? 0 : matches[7],
    //     seconds: matches[8] === undefined ? 0 : matches[8]
    // };
    const sign = matches[1] === undefined ? '+' : '-'
    const years = matches[2] === undefined ? 0 : matches[2]
    const months = matches[3] === undefined ? 0 : matches[3]
    const weeks = matches[4] === undefined ? 0 : matches[4]
    const days = matches[5] === undefined ? 0 : matches[5]
    const hours = matches[6] === undefined ? 0 : matches[6]
    const minutes = matches[7] === undefined ? 0 : matches[7]
    const seconds = matches[8] === undefined ? 0 : matches[8]
    return (((hours * 60) + parseInt(minutes)) * 60) + parseInt(seconds)
}