import { ActionCreator, Action, Reducer } from 'redux';
import * as constants from "../constants"
import { addVideoType, removeVideoType, updateVideoType, videosType, snippetVideoType, videoSnippet, resultsType, updateResultsType } from "../types"
import { number } from 'prop-types';

export const addVideo: ActionCreator<addVideoType> = () => ({
    type: constants.ADD_VIDEO,
    payload: {}
})

export const removeVideo: ActionCreator<removeVideoType> = (id: number) =>  ({
    type: constants.REMOVE_VIDEO,
    payload: id
})

export const updateVideo: ActionCreator<updateVideoType> = (data: videosType) => {
    return {
        type: constants.UPDATE_VIDEO,
        payload: data
    }
}

export const setSnippet: ActionCreator<snippetVideoType> = (id: number, data: videoSnippet) => {
    return {
        type: constants.SET_SNIPPET_VIDEO,
        payload: {
            id: id,
            snippet: data
        }
    }
}

export const updateResults: ActionCreator<updateResultsType> = (id: number, data: resultsType) => {
    return {
        type: constants.UPDATE_RESULTS,
        payload: {
            id: id,
            results: data
        }
    }
}

export default addVideo