import * as React from "react"
import "../../assets/scss/OfflineBlock.scss"
import { Link } from "react-router-dom"
import { Icon } from "antd"

const OfflineBlock = (props) => {
    return (
        <div className="block login-block">
            <p>
                The server appears to be Offline. Please refresh the page to retry.
            </p>
            <p>
                If the problem persists. Contact us for more information.
            </p>
            <div className="notification">
                <Icon type="info-circle"/>
                <p>To get access to the tool, <Link to="/contact#apply">contact us</Link></p>
                <p>To sign up for the study <a href="https://docs.google.com/forms/d/e/1FAIpQLSesovIrNhoVnKEKIwZfbrzl2u3hWsh6r9RNEGA-6UfYu2fXYw/viewform?usp=sf_link">fill up the form</a></p>
            </div>
        </div>
    )
}

export default OfflineBlock