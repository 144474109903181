import * as React from "react"
import "../../assets/scss/LoginBlock.scss"
import { useState } from "react"
import { Link } from "react-router-dom"
import { Button, Input, message, Tooltip, Icon } from "antd"
import { useCookies } from "react-cookie"
import { BACKEND_ADDRESS } from "../../constants"

const LoginBlock = (props) => {
    const [userName, setUser] = useState('')
    const [password, setPass] = useState('')
    const [cookies, setCookie, removeCookie] = useCookies(['dfin'])

    const manageSession = (direction) => {
        if (direction === "lin") {
            setCookie('dfin', true, { sameSite: true })
        } else if (direction === "lout") {
            removeCookie('dfin')
        }
    }

    const onFormSubmit = (e) => {
        e.preventDefault()
        const msg = message

        fetch(
            BACKEND_ADDRESS + '/login',
            {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    username: userName,
                    password: password
                }),
            }
        )
            .then(response => {
                return response.json()
            })
            .then(({ success, message }) => {
                if (success) {
                    msg.success(message);
                    manageSession("lin")
                } else {
                    msg.error(message);
                }
            })
            .catch(error => console.error("Error:", error))

    }

    const handleKeyPress = (e) => {
        if (/\W|_/.test(e.key)) {
            e.preventDefault()
        }
    }

    const tooltip = (<Tooltip title="a-Z and 0-9 only">
        <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
    </Tooltip>)


    return (
        <div className="block login-block">
            <form onSubmit={onFormSubmit}>
                <Input name="username" value={userName} type="text" placeholder="username" allowClear onKeyPress={handleKeyPress} onChange={(e) => { return setUser(e.target.value) }} suffix={tooltip} />
                <Input name="password" value={password} type="password" placeholder="password" allowClear onChange={(e) => { return setPass(e.target.value) }} />
                <Button htmlType="submit" disabled={userName === "" || password === ""}>Login</Button>
            </form>
            <div className="notification">
                <Icon type="warning" />
                <p>The app downloader is currently unstable, if the tool fails please <Link to="/contact">contact us</Link> for any help with deepfake detection!</p>
                <p>We're working on upgrading our system and haven't gotten to resolving the YouTube download bug for the current system.</p>
            </div>
            <div className="notification">
                <Icon type="info-circle" />
                <p>To get access to the tool, <Link to="/contact#apply">contact us</Link></p>
                <p>To sign up for the study <a href="https://docs.google.com/forms/d/e/1FAIpQLSesovIrNhoVnKEKIwZfbrzl2u3hWsh6r9RNEGA-6UfYu2fXYw/viewform?usp=sf_link">fill up the form</a></p>
            </div>
        </div>
    )
}

export default LoginBlock