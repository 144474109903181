import * as React from "react"
import TimelineBlock from "./TimelineBlock"
import "./../../assets/scss/ResultsBlock.scss"
import { videosType } from "./../../types"
import { Icon, Switch, Tag } from "antd"
import { useState, useEffect } from "react"
import DescriptionComponent from "./DescriptionComponent"
import LabelComponent from "./LabelComponent"
import OverallResultComponent from "./OverallResultComponent"
import ExportComponent from "./ExportComponent"
import { MEDIUM_FAKENESS, HIGH_FAKENESS } from "../../constants/index"

/**
 * Track the results block
 * While the results load: show loading circle
 * Update the fakeness result as the responses come in
 * Update the timeline as the responses come in
 */
interface DecriptionComponentProps {
    data: videosType
}


const videoResultsBlock = (props) => {
    const [expanded, setExpanded] = useState(false)

    const onChange = e => {
        setExpanded(!expanded)
    }

    const fakeness = props.data.results.fakeness

    const getFakenessText = (fakeness : number) => {
        let fakenessText = "real"
        if( fakeness > HIGH_FAKENESS) {
            fakenessText = "fake"
        } else if ( fakeness > MEDIUM_FAKENESS) {
            fakenessText = "suspicious"
        }
        return fakenessText
    }

    const getMax = (reel) => {
        let t, max = 0
        for (t of reel) {
            max = (t > max) ? t : max
        }
        return max
    }

    const evalPills = props.data.results.results.map(
        r => {
            if (r) {
                let iconType = null
                let tagClass = ""
                let mtype = ""
                if (r['result'] === null) {
                    iconType = "loading"
                } else {
                    if (Array.isArray(r['result'])) {
                        iconType = "check-circle"
                        tagClass = getFakenessText(getMax(r['result']))
                    } else {
                        iconType = "close-circle"
                    }
                }

                for (let m of props.methods) {
                    if(m['name'] == r['name']) {
                        mtype = m['type']
                        break
                    }
                }

                return (
                    <Tag key={"i-tag-"+r['name']} className={tagClass}>
                        <span className="text">
                            {mtype}
                        </span>
                        <Icon type={iconType} />
                    </Tag>
                )
            } else {
                return null
            }
        }
    )
    const runningEvals = props.data.results.results.map(r => (r && r['result'] == null) ? <Tag key={r['name']+"-rt"} color="magenta">{r['name']}</Tag> : null).filter(r => r != null)
    const isEvaluating = runningEvals.length > 0

    const doneMessage = (
        <div>
            We think that the video is <span className="verdict">{getFakenessText(fakeness)}</span>
        </div>
    )
    const activeMessage = (
        <div>
            Processing. Tentative verdict: <span className="verdict">{getFakenessText(fakeness)}</span>
        </div>
    )

    const bottomBar = (fakeness !== undefined) ? (
        <div className="bottom-bar">
            <div className="message">
                {
                    (runningEvals.length > 0) ? activeMessage : doneMessage
                }
            </div>
            <div className="controls">
                Simple View <Switch onChange={onChange} /> Detailed View
            </div>
        </div>
    ) : (
        <div className="bottom-bar">
            <Icon
                type="loading"
                style={{ fontSize: '16px', background: 'transparent', marginRight: '15px' }}
                spin
            /> Evaluating
        </div>
    )

    const overallFakeness = props.data.results['fakeness']*100
    
    // evaluation is ongoing if none of the results are undefined
    // const isEvaluating = props.data.results.results.map(m => (m) ? (m === null || m['result'] === null) : false).indexOf(true) > -1

    return (
        <div id="results-block">
            <div className="primary">
                { (expanded) ? <TimelineBlock reel={props.data.results.results} snippet={props.data.snippet} duration={props.data.duration} methods={props.methods} onSeek={props.onSeek} /> : (
                    <div className="individual-verdicts">
                        <span className="text">Individual verdicts</span>{evalPills}
                    </div>
                )}
            </div>
            <div className="extras">
                    <DescriptionComponent data={props.data} />
                    <LabelComponent />
                    <div className="overall-result-top">
                        <OverallResultComponent value={overallFakeness} loading={isEvaluating} />
                    </div>
                    <ExportComponent results={props.data.results.results} id={props.data.id} disabled={ props.data.results.results.length < 1 } />
            </div>
            {bottomBar}
        </div>
    )
}

export default videoResultsBlock
