import * as React from "react"
import { videosType } from "../../types"
import { Tag } from "antd"
import Moment from "react-moment"

interface DecriptionComponentProps {
    data: videosType
}

const DescriptionComponent = (props) => {
    const { title, duration, snippet, channelTitle, description, publishedAt, tags } = props.data
    return (
        <div className="sub-block description-component">
            <h2>{title}</h2>
            <ul>
                <li><span>Processed: </span>{snippet.start}:00-{snippet.end}:00 (Total {snippet.end-snippet.start}s of {duration}s)</li>
                <li><span>Channel: </span>{channelTitle}</li>
                {/* <li><span>Description: </span><p>{description}</p></li> */}
                <li><span>Published: </span><Moment format="MMMM Do, YYYY [at] h:mm:ss a">{publishedAt}</Moment></li>
                {/* <li><span>Tags: </span>{ tags.map(t => <Tag key={t+"-tag"}>{t}</Tag>)}</li> */}
            </ul>
        </div>
    )
}

export default DescriptionComponent