import * as React from "react"
import { connect } from "react-redux"
import "../../assets/scss/Content.scss"
import { Row, Col, Card, Typography } from "antd"
import { LinkOutlined } from '@ant-design/icons'

function About(props) {
    const { Meta } = Card
    const { Paragraph } = Typography
    let people = require("../../resources/people.json")
    let formerMembers = require("../../resources/former-members.json")

    const peopleBlocks = people.map(({ name, img_src, site, description }, idx) =>
        <Col md={6} sm={8} xs={24} key={"collab-" + idx + "-rit"}>
            <Card
                bordered={false}
                cover={(img_src) ? <img alt={name} src={img_src} /> : null}
                actions={(site) ? [<a href={site} target="_blank">
                    <LinkOutlined translate={""} key={"link"} />
                </a>] : null}
            >
                <Meta
                    title={name}
                    description={(description) ? <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                        {description}</Paragraph> : null}
                />
            </Card>
        </Col>
    )

    const formerMembersBlocks = formerMembers.map(({ name, img_src, site, description }, idx) =>
        <Col md={6} sm={8} xs={24} key={"former-collab-" + idx + "-rit"}>
            <Card
                bordered={false}
                cover={null}
            >
                <Meta
                    title={name}
                    description={(description) ? <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                        {description}</Paragraph> : null}
                />
            </Card>
        </Col>
    )

    let chunks = []

    for (var i = 0; i < peopleBlocks.length; i += 4) {
        chunks.push(
            <Row gutter={16} key={"people-row-" + i}>
                {(peopleBlocks[i]) ? peopleBlocks[i] : null}
                {(peopleBlocks[i + 1]) ? peopleBlocks[i + 1] : null}
                {(peopleBlocks[i + 2]) ? peopleBlocks[i + 2] : null}
                {(peopleBlocks[i + 3]) ? peopleBlocks[i + 3] : null}
            </Row>
        )
    }

    let fChunks = []

    for (var i = 0; i < formerMembersBlocks.length; i += 4) {
        fChunks.push(
            <Row gutter={16} key={"former-people-row-" + i}>
                {(formerMembersBlocks[i]) ? formerMembersBlocks[i] : null}
                {(formerMembersBlocks[i + 1]) ? formerMembersBlocks[i + 1] : null}
                {(formerMembersBlocks[i + 2]) ? formerMembersBlocks[i + 2] : null}
                {(formerMembersBlocks[i + 3]) ? formerMembersBlocks[i + 3] : null}
            </Row>
        )
    }

    return (
        <div className="page-container container">
            <h1>About the Project</h1>
            <p>
                The <strong>DeFake project</strong> is an initiative by a team of researchers at the <a href="https://www.rit.edu/cybersecurity/" target="_blank" rel="noopener">Global Cybersecurity Institute</a> at RIT, <a href="https://jnm.olemiss.edu/" target="_blank" rel="noopener noreferrer">School of Journalism and New Media</a> at University of Mississippi, and <a href="https://msu.edu/" target="_blank" rel="noopener noreferrer">Michigan State University</a>.
            </p>
            <p>
                Deepfake technology has slashed the amount of knowledge and time it takes for bad actors to generate believable malicious video content. These videos could lead to disinformation campaigns attacking democracies and ruin reputations of high profile individuals. The faith of the public in the news media could erode if these videos are published.
                Hence, we focus on developing a tool to help journalists detect deepfake videos. Our aim is to create a robust and user-friendly system that provides clear, interpretable results to tackle the challenge of digital misinformation. Our team brings together expertise in journalism, human-computer interaction, and computer vision, working towards a practical and effective solution to help ensure accuracy and trust in media.
                The team are one of eight winners of the <a href="https://knightfoundation.org/articles/announcing-the-winners-of-the-ai-and-the-news-open-challenge">Knight Foundation’s AI and the News Open Challenge</a>.
            </p>
            <h2>The Team</h2>
            <div className="site-card-wrapper">
                {chunks}
            </div>
            <h2>Former Members</h2>
            <div className="site-card-wrapper">
                {fChunks}
            </div>
            <h2>Tool Limitations</h2>
            <p>The project is currently under constant development. The current limitations of the detection include:</p>
            <ul>
                <li>The tool is currently offline for maintenance and upgrades</li>
                <li>Only pieces of videos with the subject facing the camera can yield results</li>
                <li>The models lean heavier towards detecting videos as fake</li>
            </ul>
            <h2>NSF Acknowledgement</h2>
            <p>
                This material is based upon work supported by the National Science Foundation (NSF) under Grant No. 2153112.
            </p>
            <p>
                Any opinions, findings, and conclusions or recommendations expressed in this material are those of the author(s) and do not necessarily reflect the views of the National Science Foundation.
            </p>
        </div>
    )
}

const mapStateToProps = state => state

export default connect(mapStateToProps)(About)