import * as React from "react"
import { render } from "react-dom";
import "../../assets/scss/HeaderContainer.scss"

import { connect } from "react-redux"
import { pagesType, storeState } from "../../types"

import Navigation from "../header/Navigation"
const reactLogo = require("./../../assets/img/react_logo.svg");

interface HeaderContainerProps {
    pages: Array<pagesType>
}

const HeaderContainer: React.SFC<HeaderContainerProps> = (props) => {
    return (
        <header>
            <div className="container">
                <Navigation pages={props.pages} />
            </div>
        </header>
    )
}

const mapStateToProps = (state:storeState) => ({
        pages: state.server.pages
})

export default connect(mapStateToProps)(HeaderContainer)