import { combineReducers, Reducer } from "redux"
import videoReducer  from "./videoReducers"
import serverReducer from "./serverReducer"
import { storeState } from "../types"

const allReducers: Reducer<storeState> = combineReducers<storeState>({
    videos: videoReducer,
    server: serverReducer
})

export default allReducers