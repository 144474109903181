// ./stc/configureStore.ts

import { createStore, applyMiddleware, Store } from 'redux';

// react-router has its own Redux middleware, so we'll use this
import { routerMiddleware } from "react-router-redux";
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from 'redux-devtools-extension';
// If you use react-router, don't forget to pass in your history type.
import { History } from 'history';

// Import the state interface and our combined reducers.
import allReducers from "./reducers/reducers"

export default function configureStore(
  history: History,
  initialState,
) {
  // create the composing function for our middlewares
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25});

  // We'll create our store with the combined reducers and the initial Redux state that
  // we'll be passing from our entry point.
  return createStore(
    allReducers,
    initialState,
    composeEnhancers(applyMiddleware(
      routerMiddleware(history)
    )),
  );
}

