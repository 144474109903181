import * as constants from "../constants"
import { Reducer } from 'redux';
import { serverType} from "../types"
import { SafetyCertificateFilled } from "@ant-design/icons";

const serverReducer: Reducer<serverType> = (state: serverType = { methods: [], healthy: false, pages: [] }, {type, payload}) => {
    switch (type) {
        case constants.UPDATE_METHODS:
            return {
                ...state,
                methods: payload
            }
        case constants.UPDATE_HEALTH:
            return {
                ...state,
                healthy: payload
            }
        case constants.UPDATE_SERVER:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default serverReducer