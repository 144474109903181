import * as React from "react"
import { Row, Col, Button, Input, Alert, notification } from "antd"
import { send } from "emailjs-com"
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3"
const Config = require("config")

export default function Contact(props) {
    const [name, setName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [message, setMessage] = React.useState("")
    const [captcha, setCaptcha] = React.useState(null)
    const [sent, setSent] = React.useState(false)
    const [emailPlaceholder, setEmailPlaceholder] = React.useState("Your Email Address")
    const [messagePlaceholder, setMessagePlaceholder] = React.useState("Your Message")

    const showMessage = (type, message) => {
        notification[type]({
            message: "Sorry, an error has occured.",
            description: message
        })
    }

    React.useEffect( () => {
        if(window.location.hash == '#apply') {
            setMessagePlaceholder("Please state:\n- Your position at your organization\n- What you would be using the tool for\n- And how you heard of us.")
            setEmailPlaceholder("Email address provided to you by your organization")
        }
    })

    const onFormSubmit = (e) => {
        if(captcha) {
            const templateParams = {
                from_name: email,
                to_name: 'info@defake.app',
                subject: 'Contact form from ' + name,
                message_html: message
            }
    
            send('zoho', 'template_HSFtImfP', templateParams)
            setSent(true)
            onReset()
        } else {
            showMessage('error', 'Captcha Failed')
        }
    }

    const onCaptcha = (value) => {
        setCaptcha(value)
    }

    const onReset = () => {
        setName("")
        setEmail("")
        setMessage("")
    }

    return (
        <div className="page-container container">
            <h1>Contact Page</h1>
            <p></p>
            <form className="contact-us">
                <Row>
                    <Input placeholder="Your Name" type="text" name="name" onChange={ (e) => setName(e.target.value) } value={name} allowClear required/>
                </Row>
                <Row>
                    <Input placeholder={emailPlaceholder} type="email" name="email" onChange={ (e) => setEmail(e.target.value) } value={email} allowClear required/>
                </Row>
                <Row>
                    <Input.TextArea placeholder={messagePlaceholder} name="message" cols={30} rows={10} onChange={ (e) => setMessage(e.target.value) } value={message} required />
                </Row>
                <Row>
                    <GoogleReCaptchaProvider reCaptchaKey={ Config.recaptcha }>
                        <GoogleReCaptcha onVerify={ onCaptcha } />
                    </GoogleReCaptchaProvider>
                </Row>
                <Row>
                    <Col span={4}>
                        <Button type="primary" onClick={ onFormSubmit } disabled={ name.length == 0 || email.length == 0 || message.length == 0 || captcha == null } icon="mail">Send Message</Button>
                    </Col>
                    <Col span={4}>
                        <Button type="dashed" disabled={ (name.length + email.length + message.length) == 0 } icon="reload">Reset</Button>
                    </Col>
                </Row>
            </form>
            { (sent) ? <Alert message="Message Sent" type="success" showIcon onClose={ () => setSent(false) }/> : null }
        </div>
    )
}