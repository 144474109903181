import * as React from "react"
import ReactGA from 'react-ga4'
import { Icon, message } from "antd"
import { useCookies } from "react-cookie"
import { Link, withRouter } from "react-router-dom"
import "./../../assets/scss/Navigation.scss"
import { BACKEND_ADDRESS } from "../../constants"
import { pagesType } from "../../types"
import { ExportOutlined } from '@ant-design/icons'


interface propsType {
    pages: Array<pagesType>
}

function Navigation(props) {
    const [cookie, setCookie, removeCookie] = useCookies(["dfin"])
    const [expanded, setExpanded] = React.useState(false)

    React.useEffect(() => {
        if (window["GA"]) {
            setExpanded(false)
            ReactGA.send({ hitType: 'pageview', page: props.location.pathname + props.location.search })
            console.log("Page switch to: ", props.location.pathname + props.location.search)
        }
    }, [props.location.pathname])

    const handleLogout = () => {
        const msg = message
        fetch(
            BACKEND_ADDRESS + '/logout',
            {
                method: 'POST',
                credentials: 'include',
            }
        )
            .then(response => {
                return response.json()
            }).then(({ success, message }) => {
                if (success) {
                    removeCookie("dfin")
                    msg.success(message);
                } else {
                    msg.error(message)
                }
            })
            .catch(error => console.error("Error:", error))
    }

    const links = props.pages.map((p) => {
        return <li key={p.url} className={props.location.pathname == p.url ? "selected" : ""}>
            <Link to={p.url} key={p.url}>{(p['icon']) ? <Icon type={p.icon} /> : null}{p.name}</Link>
        </li>
    })

    return <nav className="navigation">
        <div id="logo">
            <h1>DeFake</h1>
            <h2>project</h2>
        </div>
        {/* <button className={ expanded ? "hamburger expanded" : "hamburger"} onClick={ () => setExpanded(!expanded) }>
            { expanded ? <CloseOutlined translate={undefined} /> : <MenuOutlined translate={undefined}/> }
        </button> */}
        <svg className={expanded ? "hamburger active hamRotate" : "hamburger hamRotate"} viewBox="0 0 100 100" width="80" onClick={() => setExpanded(!expanded)}>
            <path
                className="line top"
                d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
            <path
                className="line middle"
                d="m 30,50 h 40" />
            <path
                className="line bottom"
                d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
        </svg>
        <div className={expanded ? "navigation-menu expanded" : "navigation-menu"}>
            <ul>
                {links}
                <li><a href="https://blog.defake.app/" rel="noopener" key="blog-link" target="_blank"><Icon type="read" />Blog<ExportOutlined translate={undefined} className="export-icon" /></a></li>
                {(cookie["dfin"]) ? <li><a onClick={handleLogout}><Icon type="logout" />Log Out</a></li> : null}
            </ul>
        </div>
    </nav>
}

export default withRouter(Navigation)