// values
export const MAX_PROCESS_DURATION_S = 60  // maximum size of chunk for processing

//video actions
export const ADD_VIDEO = 'VIDEO:ADD'
export type ADD_VIDEO = typeof ADD_VIDEO
export const REMOVE_VIDEO = 'VIDEO:REMOVE'
export type REMOVE_VIDEO = typeof REMOVE_VIDEO
export const UPDATE_VIDEO = 'VIDEO:UPDATE'
export type UPDATE_VIDEO = typeof UPDATE_VIDEO
export const DOWNLOAD_VIDEO = 'VIDEO:DOWNLOAD'
export type DOWNLOAD_VIDEO = typeof DOWNLOAD_VIDEO
export const PROCESS_VIDEO = 'VIDEO:PROCESS'
export type PROCESS_VIDEO = typeof PROCESS_VIDEO
export const GET_DEETS_VIDEO = 'VIDEO:DEETS'
export type GET_DEETS_VIDEO = typeof GET_DEETS_VIDEO
export const SET_SNIPPET_VIDEO = 'VIDEO:SNIPPET'
export type SET_SNIPPET_VIDEO = typeof SET_SNIPPET_VIDEO
export const UPDATE_RESULTS = 'VIDEO:UPDATE_RESULTS'
export type UPDATE_RESULTS = typeof UPDATE_RESULTS

//server/method actions
export const UPDATE_SERVER = 'SERVER:UPDATE'
export type UPDATE_SERVER = typeof UPDATE_SERVER
export const UPDATE_METHODS = 'METHODS:UPDATE'
export type UPDATE_METHODS = typeof UPDATE_METHODS
export const SELECT_METHODS = 'METHODS:SELECT'
export type SELECT_METHODS = typeof SELECT_METHODS

//server/page actions
export const SET_PAGE = 'PAGE:SET'
export type SET_PAGE = typeof SET_PAGE
export const INIT_PAGES = 'PAGE:INIT'
export type INIT_PAGES = typeof INIT_PAGES
export const ADD_PAGE = 'PAGE:ADD'
export type ADD_PAGE = typeof ADD_PAGE
export const DELETE_PAGE = 'PAGE:DELETE'
export type DELETE_PAGE = typeof DELETE_PAGE

//server/health actions
export const UPDATE_HEALTH = 'HEALTH:UPDATE'
export type UPDATE_HEALTH = typeof UPDATE_HEALTH

//block states
export const LOADING_STATE = 'BLOCK:LOADING'
// export type LOADING_STATE = typeof LOADING_STATE
export const INPUT_STATE = 'BLOCK:INPUT'
// export type INPUT_STATE = typeof INPUT_STATE
export const RESULTS_STATE = 'BLOCK:RESULTS'
// export type RESULTS_STATE = typeof RESULTS_STATE
export const SELECTED_STATE = 'BLOCK:SELECTED'
// export type SELECTED_STATE = typeof SELECTED_STATE
export const UPLOADING_STATE = 'BLOCK:UPLOADING'
// export type UPLOADING_STATE = typeof UPLOADING_STATE

export const MEDIUM_FAKENESS = 0.20
export const HIGH_FAKENESS = 0.50

// export const BACKEND_ADDRESS = "http://localhost:8084"
// export const BACKEND_ADDRESS = "https://miami-gpu.main.ad.rit.edu"
export const BACKEND_ADDRESS = "https://api.defake.app"