import * as React from "react"
import { connect } from "react-redux"
import "../../assets/scss/Content.scss"
import { Icon } from "antd"
import { ExportOutlined } from '@ant-design/icons'


const Media = (props) => {
    let mediaArticles = require("../../resources/media.json")
    const articles = mediaArticles.sort( (a,b) => (new Date(a.date) < new Date(b.date) ) ? 1 : -1)

    let podcasts = require("../../resources/podcasts.json")
    return (
        <div className="page-container container">
            <h1>Media</h1>
            <h2>Online Articles</h2>
            <ul className="article-list">
                {
                    articles.map( ({date, title, publisher, url}) => <li key={title+"-m"}>
                        <a href={url} target="_blank">
                            <h3>{title}</h3>
                            <span className="date"><Icon type="calendar" />{date}</span>
                            <span className="publisher">{publisher}</span>
                            <ExportOutlined translate={undefined} className="export-icon"/>
                        </a>
                    </li>)
                }
            </ul>
            <h2>Podcasts</h2>
            <ul className="article-list">
                {
                    podcasts.map( ({title, publisher, url, app}) => <li key={title+"-pc"}>
                        <a href={url} target="_blank">
                            <h3>{title}</h3>
                            <span className="publisher">{publisher}</span>
                            <span className="app">{app}</span>
                        </a>
                    </li>)
                }
            </ul>
        </div>
    )
}

const mapStateToProps = state  => state

export default connect(mapStateToProps)(Media)