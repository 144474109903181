import * as React from "react"
import "./../../assets/scss/LoadingBlock.scss"
import { Skeleton, Icon, Timeline } from "antd"

interface propsType {
    status: string
}

const LoadingBlock = (props: propsType) => {


    return (
        <div className="sub-block">
            <Icon
                type="loading"
                style={{ fontSize: '16px', background: 'transparent', marginRight: '15px' }}
                spin
            /> Preprocessing {status}
            <Skeleton active={true} />
        </div>
    )
}

export default LoadingBlock