import * as React from "react"
import { useCookies } from "react-cookie"
import { useState, useEffect } from "react"
// import ReactPlayer from "react-player"
import { Slider, Button, Switch, Checkbox, Row, Col, Alert } from "antd" // https://ant.design/components/slider/
import { MAX_PROCESS_DURATION_S } from "../../constants"
import { updateVideo } from "../../actions/videoActions"

// import { start } from "repl";
// import { statement } from "@babel/template"

interface videoProcessProps {
    onSubmit
    onBack
    title: string
    duration: number
    setVideoPlaying
    seekTo
    methods
    selectMethods
    updateVideo
    id
}

const VideoSelectedBlock = (props: videoProcessProps) => {
    const advancedMethodsCookie = 'advanced-methods'
    const [cookies, setCookie, removeCookie] = useCookies([advancedMethodsCookie])
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(Math.min(Math.floor(100 / props.duration * MAX_PROCESS_DURATION_S), 100))
    const [advanced, setAdvanced] = useState(cookies[advancedMethodsCookie] == "true")
    const [checkedMethods, setCheckedMethods] = useState(props.methods.reduce(((carry, method) => {
        if (method["active"]) {
            carry.push(method["name"])
        }
        return carry
    }), []))

    const fractToTime = (val) => val / 100 * props.duration

    const onChange = (value) => {
        props.setVideoPlaying(false)
        const diff = value[1] - value[0] // 0 to (100 or total video size)
        const threshold = Math.floor(100 / props.duration * MAX_PROCESS_DURATION_S)
        if (diff > threshold) {
            setStartTime((prevState) => {
                if (prevState !== value[0]) {
                    // moved start bubble
                    props.seekTo(fractToTime(value[0]))
                    return value[0]
                } else {
                    // moved end bubble
                    props.seekTo(fractToTime(value[1]))
                    return value[1] - threshold
                }
            })
            setEndTime((prevState) => {
                if (prevState === value[1]) {
                    // moved start bubble
                    props.seekTo(fractToTime(value[0]))
                    return value[0] + threshold
                } else {
                    // moved end bubble
                    props.seekTo(fractToTime(value[1]))
                    return value[1]
                }
            })
        } else if (value[0] >= value[1]) {
            setStartTime((prevState) => {
                (prevState !== value[0])
                    ? props.seekTo(fractToTime(value[0]))
                    : props.seekTo(fractToTime(value[1]))
                return value[1]
            })
            setEndTime((prevState) => {
                (prevState === value[1])
                    ? props.seekTo(fractToTime(value[0]))
                    : props.seekTo(fractToTime(value[1]))
                return value[1]

            })
        } else {
            setStartTime((prevState) => {
                (prevState !== value[0])
                    ? props.seekTo(fractToTime(value[0]))
                    : props.seekTo(fractToTime(value[1]))
                return value[0]
            })
            setEndTime((prevState) => {
                (prevState === value[1])
                    ? props.seekTo(fractToTime(value[0]))
                    : props.seekTo(fractToTime(value[1]))
                return value[1]
            })
        }
    }

    const tipFormatter = (value) => {
        return (Math.floor(value * props.duration / 100)).toString() + "s"
    }

    const toggleAdvanced = (checked) => {
        setAdvanced(!advanced)
        setCookie(advancedMethodsCookie, checked)
    }

    const checkboxHandler = checkedList => {
        setCheckedMethods(checkedList)
        props.selectMethods(checkedList)
        props.updateVideo({
            id: props.id,
            results: {
                results: checkedList.map(
                    m => ({
                        name: m,
                        result: null,
                        job_id: null
                    })
                )
            }
        })
    }

    const methodsList = props.methods.map(method => {
        return method["name"]
    })

    const advancedSettings = (
        <div className="sub-block advanced-settings">
            <Alert message="Advanced settings are active" type="info" closeText="Close Now" className="block-alert" />
            <Checkbox.Group options={methodsList} value={checkedMethods} onChange={checkboxHandler} />
        </div>
    )

    const formContent = (
        <div className="selection-block">
            <div className="video-controls">
                <Slider tooltipVisible={true} range step={2} value={[startTime, endTime]} tipFormatter={tipFormatter} onChange={onChange} />
                <input style={{ display: 'none' }} type="number" name="start" value={fractToTime(startTime)} onChange={() => console.log('')} />
                <input style={{ display: 'none' }} type="number" name="end" value={fractToTime(endTime)} onChange={() => console.log('')} />
            </div>
            <div className="details">
                <div className="sub-block number-block">
                    <p>Duration:</p>
                    <p>{Math.round(fractToTime(endTime - startTime))}s</p>
                </div>
                <div className="sub-block description">
                    <p>
                        Drag to select an interval for processing. An interval size has to be between 5 and 60 seconds.
                    </p>
                    <p className="notify">
                        Note: (1) Processing time will depend on interval size. (2) Please try to select intervals where the subject is facing the camera as looking away will not yield proper results.
                    </p>
                </div>
                <div className="sub-block slim stretch selected-process-buttons">
                    <Button type="dashed" onClick={props.onBack}>Back</Button>
                    <Button className="button" type="primary" htmlType="submit" disabled={checkedMethods.length == 0}>Process</Button>
                </div>
                <div className="sub-block stretch advanced-toggle">
                    Advanced Settings
                    <Switch onChange={toggleAdvanced} defaultChecked={advanced} />
                </div>
                {(advanced) ? advancedSettings : null}
            </div>
        </div>)

    return (
        <form onSubmit={props.onSubmit}>
            {formContent}
        </form>
    )
}

export default VideoSelectedBlock