import * as React from "react"
import "../../assets/scss/FooterContainer.scss"
import { Row, Col } from "antd"

interface FooterContainerProps { }

const FooterContainer: React.SFC<FooterContainerProps> = (props) => {
    return (
        <footer>
            <Row>
                <Col xs={24} sm={16}>
                    <Row>
                        <p>Developed by:</p>
                        <Col xs={24} sm={12}>
                            <a target="_blank" rel="noopener" href="https://www.rit.edu/"><img src="https://people.rit.edu/js8365/images/rit.png" alt="Rochester Institute of Technology" /></a>
                        </Col>
                        <Col xs={24} sm={12}>
                            <a target="_blank" rel="noopener" href="https://olemiss.edu/"><img src="https://people.rit.edu/js8365/images/umiss-logo.webp" alt="University of Mississippi" /></a>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={8}>
                    <Row>
                        <p>Funded by:</p>
                        <Col xs={8} sm={8}>
                            <a rel="noopener" className="invert-logo" target="_blank" href="https://aiethicsinitiative.org/">
                                {/* The Ethics and Governance of Artificial Intelligence Initiative */}
                                <img src="https://people.rit.edu/js8365/images/aifund-logo.png" alt="The Ethics and Governance of Artificial Intelligence Initiative" />
                            </a>
                        </Col>
                        <Col xs={24} sm={8}>
                            <a rel="noopener" target="_blank" href="https://knightfoundation.org/"><img src="https://people.rit.edu/js8365/images/kf.png" alt="Knight Foundation" /></a>
                        </Col>
                        <Col xs={16} sm={8}>
                            <a rel="noopener" target="_blank" href="https://www.nsf.gov/"><img src="https://people.rit.edu/js8365/images/nsf.png" alt="Knight Foundation" /></a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </footer>
    )
}

export default FooterContainer